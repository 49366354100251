const DefaultHeader = () => {
    return (
        <nav className="shell-app-header">
            <div className="shell-app-logo">
                <div className="switcher">
                    <a>
                        <img src="/assets/images/menu_dots_icon.svg" />
                    </a>
                </div>

                <h1 className="forceone-title">
                    <span className="forcepoint-title">
                        Forcepoint <span className="security-title">Security Cloud</span>
                    </span>
                </h1>
            </div>
        </nav>
    )
}

export default DefaultHeader
