import { useEffect, useState } from "react"
import * as singleSpa from "single-spa"
import {
  coreStore,
  SET_IFRAME_URL,
  SET_ACTIVE_MENU
} from "@forcepoint/platform-coreui"

const SubMenu = ({ subMenus, data }) => {
  const [activeMenu, setActiveMenu] = useState<any>(
    coreStore.getState()?.activeMenu
  )
  useEffect(() => {
    const navigationSubscription = coreStore.subscribe(() => {
      const state = coreStore.getState()
      setActiveMenu(state.activeMenu)
    })
    return () => {
      navigationSubscription()
    }
  }, [])
  return (
    <div className="sub-menu-list-container">
      {subMenus.map((x) => (
        <div
          key={x?.id}
          className={`sub-menu ${x?.url.startsWith(activeMenu?.url) ? "active-sub-menu" : ""}`}
          data-testid={`sub-menu-${x?.localizationKey || x?.name}`}
          onClick={() => {
            if (data.openInIframe) {
              const stateData = coreStore.getState()
              if (
                stateData.activeApp.applicationCode !== data.applicationCode
              ) {
                singleSpa.navigateToUrl(data?.applicationURL)
              }
              coreStore.dispatch({
                type: SET_IFRAME_URL,
                url:
                  data.menus[0].url !== data.applicationUrl
                    ? `${data?.iframeURL}${x?.url}`
                    : data.iframeURL
              })
              coreStore.dispatch({
                type: SET_ACTIVE_MENU,
                url: x?.url
              })
            } else {
              singleSpa.navigateToUrl(x?.url)
            }
            const event = new CustomEvent("menuItemClick", {
              detail: { application: data, menu: x }
            })
            window.dispatchEvent(event)
          }}
          onKeyDown={() => { }}
        >
          <div className="sub-menu-item" onKeyDown={() => { }}>
            {x?.localizationKey || x?.name}
          </div>
        </div>
      ))}
    </div>
  )
}

export default SubMenu
