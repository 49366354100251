import SubMenu from "./SubMenu"
import { NeoComponents } from "@forcepoint/platform-utilityui"
import * as singleSpa from "single-spa"
import { APP_URL } from "@forcepoint/platform-coreui"

interface MenuProps {
  data: any
  setOpenMenuCode: (code: string) => void
  openMenuCode: string
  activeAppCode: string
}

const Menu: React.FC<MenuProps> = ({
  data,
  setOpenMenuCode,
  openMenuCode,
  activeAppCode
}) => {
  /**
   * To get active app code
   * @returns
   */
  const getActiveAppCode = () => {
    if (
      window.location.pathname.startsWith("/settings") &&
      data.applicationName === "Settings"
    ) {
      return true
    } else {
      return (
        activeAppCode === data.applicationCode &&
        !window.location.pathname.startsWith("/settings")
      )
    }
  }

  /**
   * First level menu click action
   */
  const firstLevelMenuClickAction = () => {
    if (openMenuCode === data?.applicationCode && data?.menus?.length > 0) {
      setOpenMenuCode(null)
    } else if (data.applicationType === "external") {
      const applicationUrl = APP_URL.endsWith(".eu")
        ? data.iframeURL
        : data.applicationURL
      window.open(applicationUrl, data.openInNewTab ? "_blank" : "_self")
    } else if (data?.menus?.length < 1) {
      singleSpa.navigateToUrl(data.applicationURL)
    } else {
      setOpenMenuCode(data?.applicationCode)
    }
  }

  /**
   * UI Template
   */
  return (
    <div className="menu-container">
      <div
        className="menu-item"
        data-testid={`menu-item-${data?.applicationName}`}
        onKeyDown={() => { }}
        onClick={() => firstLevelMenuClickAction()}
      >
        <div className="icon-app-name">
          <span>
            <NeoComponents.NeoIcon
              name={data?.productLogo || "applications"}
              size={16}
              fill={`${getActiveAppCode() ? "#00AF9A" : "#3D4E5C"}`}
              data-always="true"
            />
          </span>

          <span
            className={`appName ${getActiveAppCode() ? "active-appName" : ""}`}
          >
            {data?.applicationName}
          </span>
        </div>
        {data?.menus?.length > 0 ? (
          <span>
            {openMenuCode === data.applicationCode ? (
              <div className="carat-up-icon">
                <NeoComponents.NeoIcon
                  name="carat-up"
                  size={8}
                  fill="#000"
                  data-always="true"
                  data-dark-tip="Close"
                />
              </div>
            ) : (
              <div className="carat-down-icon">
                <NeoComponents.NeoIcon
                  name="carat-down"
                  size={8}
                  fill="#000"
                  data-always="true"
                  data-dark-tip="Open"
                />
              </div>
            )}
          </span>
        ) : null}
      </div>
      {data?.menus?.length > 0 && openMenuCode === data.applicationCode ? (
        <SubMenu subMenus={data?.menus} data={data} />
      ) : null}
    </div>
  )
}

export default Menu
