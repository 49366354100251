const PageTitle = ({ name, pageTitle }) => {
    const isEditProfile = window?.location?.pathname?.includes("editprofile")

    return (
        <>
            <h1 className="forceone-title">
                <span className="forcepoint-title">
                    Forcepoint <span className="security-title">Security Cloud</span>
                </span>
                <div className="pos-relative forcepoint-title" style={{ top: "-1px" }}>
                    {isEditProfile ? "Edit Profile" : name}
                </div>
            </h1>
            {pageTitle ? (
                <h1 className="forcepoint-subtitle">&nbsp;- {pageTitle}</h1>
            ) : null}
        </>
    )
}

export default PageTitle