import { useEffect, useState } from "react"
import { coreStore } from "@forcepoint/platform-coreui"
import { isEmpty } from "@forcepoint/platform-utilityui"
import Menu from "./Menu"
import CollapsedSideBar from "./CollapsedSideBar"

export default function SideMenu({ isExpandedSideBar, setIsExpandedSideBar }) {
  // ----------------------------------------------------------------------------------------
  // -------------------------------- Component States --------------------------------------
  // ----------------------------------------------------------------------------------------

  const [activeAppCode, setActiveAppCode] = useState(
    window.location.pathname.startsWith("/settings")
      ? "settings"
      : coreStore.getState()?.activeApp?.applicationCode
  )

  const [openMenuCode, setOpenMenuCode] = useState(activeAppCode)

  const sideBarOpenStatus = sessionStorage.getItem("isExpanded")

  const [sideMenuData, setSideMenuData] = useState([])

  const [applications, setApplications] = useState([])

  const [platformServices, setPlatformServices] = useState([])

  function sideMenuUrl() {
    const applications = coreStore.getState()?.applications || []
    const homeApp = applications.find((app) => app.applicationName === "Home")

    const getInsightsApp = () => {
      const insightsApp = applications.find(
        (app) => app.applicationName === "Insights"
      )
      return insightsApp
    }
    if (homeApp) {
      return homeApp.menus[0]?.url
    } else if (getInsightsApp()) {
      return getInsightsApp()?.url
    } else {
      const defaultApp = applications.find(
        (app) => app.isSPA && !app.openInIframe && app.menus?.length > 0
      )
      return defaultApp.menus[0].url
    }
  }

  useEffect(() => {
    const applications = coreStore.getState()?.applications || []
    const sideMenuDataCopy = [
      {
        applicationName: "Home",
        productLogo: "home",
        applicationCode: "HOME",
        url: sideMenuUrl(),
        menus: []
      },
      ...applications.filter(
        (a) =>
          a.applicationCode.toLowerCase() !== "home" &&
          a.showInSwitcher &&
          ((a.isSPA && !isEmpty(a.menus)) ||
            a.applicationType === "external" ||
            a.openInIframe)
      ),
      {
        applicationName: "Settings",
        productLogo: "settings",
        applicationCode: "settings",
        menus: coreStore.getState()?.settingsMenu
      }
    ]

    const filteredApps = sideMenuDataCopy.reduce(
      (a, b) => {
        if (b.menuType !== "application") {
          a.core.push(b)
        } else {
          a.applications.push(b)
        }
        return a
      },
      { applications: [], core: [] }
    )
    setPlatformServices(filteredApps.core)
    setApplications(filteredApps.applications)
    setSideMenuData(sideMenuDataCopy)
  }, [])

  useEffect(() => {
    if (sideBarOpenStatus === null) {
      sessionStorage.setItem("isExpanded", "true")
      setIsExpandedSideBar(true)
    }
  }, [])

  useEffect(() => {
    const coreStoreSubscription = coreStore.subscribe(() => {
      const stateData = coreStore.getState()
      setActiveAppCode(
        window.location.pathname.startsWith("/settings")
          ? "settings"
          : stateData?.activeApp?.applicationCode
      )
      setOpenMenuCode(
        window.location.pathname.startsWith("/settings")
          ? "settings"
          : stateData?.activeApp?.applicationCode
      )
    })
    return () => {
      coreStoreSubscription()
    }
  }, [isExpandedSideBar])

  return (
    <div
      className={` ${isExpandedSideBar ? "main-sidemenu-container" : "collapsed-sidemenu-container"}`}
      style={{
        width: `${isExpandedSideBar ? "var(--platform-sidebar-width)" : "var(--platform-sidebar-width-collapsed)"}`
      }}
    >
      {isExpandedSideBar ? (
        <div className="side-menu-container">
          <div className="side-menu-app-container">
            {applications.length > 0 ? (
              <div className="side-menu-list applications-container">
                <span className="app-type-text">Applications</span>
                {applications.map((x) => {
                  return (
                    <Menu
                      key={x.id}
                      data={x}
                      setOpenMenuCode={setOpenMenuCode}
                      openMenuCode={openMenuCode}
                      activeAppCode={activeAppCode}
                    />
                  )
                })}
              </div>
            ) : null}

            <div className="side-menu-list platform-services-container">
              <span className="app-type-text">Platform services</span>
              {platformServices.map((x) => {
                return (
                  <Menu
                    key={x.id}
                    data={x}
                    setOpenMenuCode={setOpenMenuCode}
                    openMenuCode={openMenuCode}
                    activeAppCode={activeAppCode}
                  />
                )
              })}
            </div>
          </div>
          <div
            className="collapse-text-container"
            onKeyDown={() => { }}
            onClick={() => {
              setIsExpandedSideBar(false)
              sessionStorage.setItem("isExpanded", "false")
            }}
          >
            <div>
              <img
                src="/assets/images/chevrons-left.svg"
                className="collapse-icon"
              />
            </div>
            <div className="collapse-txt">Collapse</div>
          </div>
        </div>
      ) : (
        <CollapsedSideBar
          setIsExpandedSideBar={setIsExpandedSideBar}
          sideMenuData={sideMenuData}
        />
      )}
    </div>
  )
}
