import { getHelpUrl } from "../../utility/header.utility"
import NotificationAction from "./NotificationAction/NotificationAction"
import SearchActions from "./ActionButtons/SearchActions"
import SettingsActions from "./ActionButtons/SettingsActions"
import HelpAction from "./ActionButtons/HelpAction"
import ProfileAction from "./ProfileAction/ProfileAction"

const QuickActionButtons = ({
    setCurrentDropdown,
    currentDropdown,
    stateData
}) => {
    const settingsMenuList = stateData?.settingsMenu
    const helpUrl = getHelpUrl(stateData)
    const activeApp = stateData?.activeApp

    return (
        <ul className="shell-menu">
            <SearchActions activeApp={activeApp} />
            <SettingsActions settingsMenuList={settingsMenuList} />
            <HelpAction helpUrl={helpUrl} />
            <li className="vertical-line">
                <a
                    onClick={(e) => e.preventDefault()}
                    onKeyDown={() => { }}
                    aria-label="Placeholder for action"
                >
                    {/* Link does not need visible content but is accessible */}
                </a>
            </li>
            <NotificationAction
                stateData={stateData}
                setCurrentDropdown={setCurrentDropdown}
                currentDropdown={currentDropdown}
            />
            <ProfileAction
                stateData={stateData}
                setCurrentDropdown={setCurrentDropdown}
                currentDropdown={currentDropdown}
            />
        </ul>
    )
}

export default QuickActionButtons