import {
    coreStore,
    APP_LOGOUT,
    APP_TENANT,
    SET_ACTIVE_APP,
    showFoneAlert,
    closeFoneAlert
} from "@forcepoint/platform-coreui"
import { navigateToUrl } from "single-spa"
import { getUserInitials } from "../../../utility/header.utility"
import { useState } from "react"
import ProfileInfoModal from "./ProfileInfoModal"
import ChangePasswordPopup from "./ChangePasswordModal"

const ProfileAction = ({ stateData, setCurrentDropdown, currentDropdown }) => {
    const userInfo = stateData?.userInfo
    /**
     * To store type of tenant-superadmin/mssp/distributor
     */
    const tenantType = sessionStorage.getItem("tenantType")

    const { username, userInitials } = getUserInitials(userInfo)

    /**
     * To store app messages
     */
    const appMessages = stateData.applicationMessages

    const regex = /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/

    const returnMapping = {
        tenant: "Return to Superadmin",
        distributor: "Return to Distributor",
        mssp: "Return to MSSP"
    }

    const returnText = returnMapping[tenantType] || "Return to Superadmin" // Default to "Return to Superadmin"

    /**
     * To set profile info modal status
     */
    const [profileInfoModalStatus, setProfileInfoModalStatus] =
        useState<boolean>(false)

    /**
     * To set Change Password popup status
     */
    const [changePasswordPopupStatus, setChangePasswordPopupStatus] =
        useState<boolean>(false)

    /**
     * Show logout alert
     */
    const showLogoutAlert = () => {
        showFoneAlert({
            title: "Sign Out",
            text: appMessages.messages.header.CONFIRM_SIGN_OUT,
            positiveBtnText: "Sign Out",
            negativeBtnText: "No",
            showNegativeBtn: true,
            positiveBtnCallback: () => {
                sessionStorage.removeItem("tenantType")
                sessionStorage.removeItem("tenantName")
                coreStore.dispatch({ type: APP_LOGOUT })
                navigateToUrl("/logout")
                closeFoneAlert()
            },
            negativeBtnCallback: () => {
                closeFoneAlert()
            },
            type: "warning"
        })
    }

    return (
        <>
            <li className="shell-dropdown-container user-dropdown">
                <a
                    id="profile_dropdown_link"
                    onKeyDown={() => { }}
                    className={`${sessionStorage.getItem("isEmulated") ? "emulated" : ""
                        }`}
                    onClick={(e) => {
                        e.preventDefault()
                        setCurrentDropdown("profile")
                    }}
                >
                    {userInitials}
                </a>
                <ul
                    className={
                        "shell-dropdown user-dropdown-menu " +
                        (currentDropdown === "profile" ? "show" : "")
                    }
                >
                    <li
                        id="profile_info_link"
                        onKeyDown={() => { }}
                        style={{
                            cursor: "pointer",
                            padding: "14px 0px",
                            margin: "0px"
                        }}
                        onClick={() => {
                            setProfileInfoModalStatus(true)
                        }}
                    >
                        <span className="li-span">{userInitials}</span>
                        <span
                            className={`li-span-text ${!regex.exec(username) ? "capitalize" : "email"
                                }`}
                        >
                            {username}
                        </span>
                    </li>
                    <li className="li-line"></li>
                    <li>
                        <a
                            id="edit_profile_link"
                            onKeyDown={() => { }}
                            onClick={(e) => {
                                e.preventDefault()
                                sessionStorage.setItem(
                                    "editProfilePrevUrl",
                                    window.location.pathname
                                )
                                coreStore.dispatch({
                                    type: SET_ACTIVE_APP,
                                    appName: null
                                })
                                navigateToUrl(`/editprofile`)
                            }}
                        >
                            Edit Profile
                        </a>
                    </li>
                    {sessionStorage.getItem("isEmulated") && (
                        <li className="emulated-item">
                            <a
                                id="return_to_link"
                                onKeyDown={() => { }}
                                onClick={(e) => {
                                    e.preventDefault()
                                    sessionStorage.removeItem("isEmulated")
                                    document.cookie = `${APP_TENANT}cookie.oidc.auth=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; Domain=${""}`
                                    document.cookie = `${APP_TENANT}cookie.oidc.refresh=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; Domain=${""}`
                                    window.location.href = "/opsc/organization"
                                }}
                            >
                                {returnText}
                            </a>
                        </li>
                    )}
                    {userInfo?.idpCode === "local" &&
                        !sessionStorage.getItem("isEmulated") && (
                            <li>
                                <a
                                    id="change_password_link"
                                    onKeyDown={() => { }}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        setChangePasswordPopupStatus(true)
                                    }}
                                >
                                    Change Password
                                </a>
                            </li>
                        )}

                    <li>
                        <a
                            id="sign_out_link"
                            onKeyDown={() => { }}
                            onClick={(e) => {
                                e.preventDefault()
                                showLogoutAlert()
                            }}
                        >
                            Sign Out
                        </a>
                    </li>
                </ul>
            </li>
            {profileInfoModalStatus && (
                <ProfileInfoModal
                    profileInfoModalStatus={profileInfoModalStatus}
                    setProfileInfoModalStatus={setProfileInfoModalStatus}
                />
            )}
            {changePasswordPopupStatus && (
                <ChangePasswordPopup
                    changePasswordPopupStatus={changePasswordPopupStatus}
                    setChangePasswordPopupStatus={setChangePasswordPopupStatus}
                />
            )}
        </>
    )
}

export default ProfileAction
