import { NeoComponents } from "@forcepoint/platform-utilityui"
import * as singleSpa from "single-spa"
import { coreStore } from "@forcepoint/platform-coreui"

import { useState, useRef, FC } from "react"
const CollapsedSideBar: FC<{
  setIsExpandedSideBar: React.Dispatch<boolean>
  sideMenuData: any
}> = ({ setIsExpandedSideBar, sideMenuData }) => {
  const activeAppCode = coreStore.getState()?.activeApp?.applicationCode

  const location = window?.location?.pathname

  const [isOpen, setIsOpen] = useState(null)

  const [position, setPosition] = useState({
    x: 0,
    y: 0,
    spaceAbove: 0,
    spaceBelow: 0,
    height: 0
  })

  const popupRef = useRef(null)

  const [hoveredMenu, setHoveredMenu] = useState(null)

  function setPopupPositions(ev) {
    const iconRect = ev.target.getBoundingClientRect()
    const spaceBelow = window.innerHeight - iconRect.bottom
    const spaceAbove = iconRect.top
    const { x, width, y, height } = iconRect
    const popupPos = {
      x: x + width + 10,
      y: y,
      spaceAbove,
      spaceBelow,
      height
    }
    setPosition(popupPos)
  }

  const getPopupStyles = () => {
    const pos = { ...position }
    // calculations based on menu element count + padding + lineheight
    const menuLen = (17.4 + 8 + 8) * (hoveredMenu?.menus?.length ?? 0)
    const tooltipHeight = menuLen + 32 + 16
    if (tooltipHeight > pos.spaceBelow && pos.spaceAbove >= tooltipHeight) {
      pos.y = pos.y - tooltipHeight + pos.height
    } else {
    }
    return {
      left: pos.x - 10,
      top: pos.y
    }
  }

  const getActiveAppCode = (data) => {
    if (
      window.location.pathname.startsWith("/settings") &&
      data.applicationName === "Settings"
    ) {
      return true
    } else {
      return (
        activeAppCode === data.applicationCode &&
        !window.location.pathname.startsWith("/settings")
      )
    }
  }

  return (
    <div className="collapsed-menu-container">
      <div
        className="menu-icons"
        onMouseLeave={() => {
          setHoveredMenu(null)
          setIsOpen(false)
        }}
      >
        {sideMenuData.map((x) => (
          <div
            className="collapse-menu-item"
            onKeyDown={() => { }}
            key={x?.applicationCode}
            onMouseEnter={(ev) => {
              if (x?.menus?.length > 0) {
                setHoveredMenu(x)
                setIsOpen(true)
                setPopupPositions(ev)
              }
            }}
            onClick={() => {
              if (x?.menus.length < 1) {
                singleSpa.navigateToUrl(x?.url)
              }
            }}
          >
            <div
              className={`collapsable-icon-box ${getActiveAppCode(x) ? "active-collapsable-icon-box" : ""}`}
            >
              <NeoComponents.NeoIcon
                name={x?.productLogo || "applications"}
                size={20}
                fill={`${getActiveAppCode(x) ? "#00AF9A" : "#3D4E5C"}`}
                data-always="true"
              />
            </div>
          </div>
        ))}
        <div
          style={{ position: "fixed", maxHeight: "400px", ...getPopupStyles() }}
          ref={popupRef}
        >
          <div
            className={`collapse-popup-container ${isOpen ? "" : "popup-hidden"}`}
          >
            <div className="popup-title">
              {hoveredMenu?.applicationName?.toUpperCase()}
            </div>
            <div className="popup-submenus-items">
              {hoveredMenu?.menus?.map((y) => (
                <div
                  className={`popup-menus ${location === y?.url ? "active-popup-menus" : ""}`}
                  onKeyDown={() => { }}
                  onClick={() => singleSpa.navigateToUrl(`${y?.url}`)}
                  key={y?.localizationKey || y?.name}
                >
                  {y?.localizationKey || y?.name}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div
        className="right-icon"
        onKeyDown={() => { }}
        onClick={() => {
          sessionStorage.setItem("isExpanded", "true")
          setIsExpandedSideBar(true)
        }}
      >
        <img
          src="/assets/images/chevrons-right.svg"
          className="collapse-icon-collapsed"
          alt="right"
        />
      </div>
    </div>
  )
}

export default CollapsedSideBar
