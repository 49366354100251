import Header from "./components/Header/Header"
import SideMenu from "./components/Sidebar/Sidebar"
import { useState, useEffect } from "react"
import { Provider } from "react-redux"
import {
  coreStore,
  runTimeoutForRefreshToken,
  SET_ID_TOKEN,
  SET_USER_TOKEN
} from "@forcepoint/platform-coreui"
import GlobalLoader from "./components/GlobalLoader/GlobalLoader"
import {
  NeoErrorBoundary,
  NeoThemeProvider,
  Intl,
  NeoComponents,
  Toastr,
  messages,
  FoneAlert
} from "@forcepoint/platform-utilityui"
import EulaAcceptanceModal from "./components/Eula/EulaAcceptanceModal"
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom"
import UserProfileContainer from "./components/UserProfile/UserProfileContainer"
import store from "./common/state/app.state"

let navigationSubscription = null
/**
 * Navigation UI root.
 * @param props
 * @returns
 */
export default function Root() {
  // -----------------------------------------------------------------------------------------
  // ---------------------------------- Component States ------------------------------------
  // ----------------------------------------------------------------------------------------
  const path = window?.location?.pathname?.includes("editprofile")

  /**
   * To store state data
   */
  const stateData = coreStore.getState()

  /**
   * To show sidebar
   */
  const [showSidebar, setShowSidebar] = useState<boolean>(false)

  /**
   * To set app title
   */
  const [appTitle, setAppTitle] = useState<string>(stateData.appTitle)

  /**
   * To set applications
   */
  const [applications, setApplications] = useState(stateData.applications)

  /**
   * To set active app
   */
  const [activeApp, setActiveApp] = useState(stateData.activeApp)

  /**
   * To set global loader
   */
  const [globalLoader, setGlobalLoader] = useState<boolean>(
    stateData.showGlobalLoader
  )

  /**
   * To set page title
   */
  const [pageTitle, setPageTitle] = useState<string>(stateData.pageTitle)

  /**
   * To set settings sidebar status
   */
  const [settingsSidebar, setSettingsSidebar] = useState<boolean>(
    stateData.isSettingsPage
  )

  /**
   * To show sidebar
   */
  const [showEulaAcceptance, setShowEulaAcceptance] = useState(false)

  /**
   * To store eula details
   */
  const [eulaObj, setEulaObj] = useState({})

  /**
   * To show white loader
   */
  const [showWhiteLoader, setShowWhiteLoader] = useState<boolean>(
    stateData.showWhiteLoader
  )

  /**
   * To store fone alert config
   */
  const [foneAlertConfig, setFoneAlertConfig] = useState({
    status: false,
    alertProps: null
  })

  const sideBarData = sessionStorage.getItem("isExpanded")

  const [isExpandedSideBar, setIsExpandedSideBar] = useState(
    JSON.parse(sideBarData)
  )

  useEffect(() => {
    if (isExpandedSideBar) {
      document.querySelector("body").classList.add("expanded-sidebar")
    } else {
      document.querySelector("body").classList.remove("expanded-sidebar")
    }
  }, [isExpandedSideBar])

  /**
   * To store event method
   */
  const eventMethod = window.addEventListener
    ? "addEventListener"
    : "attachEvent"

  /**
   * To store window event
   */
  const windowEvent = window[eventMethod]

  /**
   * To store message event
   */
  const messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message"

  // ----------------------------------------------------------------------------------------
  // --------------------------------- Component Effects ------------------------------------
  // ----------------------------------------------------------------------------------------

  const updateGlobalLoader = (storeData: any) => {
    setGlobalLoader(storeData.showGlobalLoader) // Update global loader show status
  }

  const updateEulaConfig = (storeData: any) => {
    let eulaConfig = null
    if (storeData.eulaConfig?.pendingEulas?.length > 0) {
      eulaConfig = storeData.eulaConfig
      setShowEulaAcceptance(true)
      setGlobalLoader(false)
    }
    setEulaObj(eulaConfig)
  }

  const updateSidebar = (storeData: any) => {
    const hasSidebar = storeData.activeApp?.modules?.uiConfig?.enableSidebar
    if (hasSidebar) {
      document.body.classList.add("with-sidebar")
      setShowSidebar(true)
    } else {
      document.body.classList.remove("with-sidebar")
      setShowSidebar(false)
    }
  }

  const updateAppDetails = (storeData: any) => {
    setActiveApp(storeData.activeApp)
    setAppTitle(storeData.appTitle)
    setPageTitle(storeData.pageTitle)
    setSettingsSidebar(storeData.isSettingsPage)
  }

  const updateMountedApps = (storeData: any) => {
    storeData.mountedApps.forEach((mountedApp: any) => {
      if (mountedApp.location) {
        const element: HTMLElement = document.getElementById(
          `single-spa-application:${mountedApp.SPAName}`
        )
        element?.classList.add("SPA-Pos-" + mountedApp?.location)
        if (mountedApp.location === "header") {
          const shellMenu: HTMLElement = document.querySelector(".shell-menu")
          if (element) {
            element.style.right = `${shellMenu?.offsetWidth + 44}px`
          }
        }
      }
    })
  }

  if (!navigationSubscription) {
    navigationSubscription = coreStore.subscribe(() => {
      const storeData = coreStore.getState() // Get state data

      setShowWhiteLoader(storeData.showWhiteLoader) // Update white loader
      updateGlobalLoader(storeData) // Update global loader
      updateEulaConfig(storeData) // Update EULA config
      setFoneAlertConfig(storeData.foneAlertConfig) // Set fone alert config

      if (storeData.activeApp) {
        updateAppDetails(storeData) // Update app details
        updateSidebar(storeData) // Update sidebar visibility and menus
      } else {
        setActiveApp(null)
        setPageTitle(storeData.pageTitle)
        setAppTitle(null)
        setShowSidebar(false)
        setSettingsSidebar(false)
        document.body.classList.remove("with-sidebar")
      }

      if (storeData.applications.length > 0) {
        setApplications(storeData.applications) // Set applications
      }

      updateMountedApps(storeData) // Update mounted apps if any
    })
  }

  /**
   * Core store subscriber call
   */
  useEffect(() => {
    windowEvent(
      messageEvent,
      (event) => {
        if (event.origin !== window.location.origin) return false
        if (event.data.message === "NEW_TOKEN_RECEIVED") {
          document.querySelector(".authIframe")?.remove()
          sessionStorage.removeItem("cookieCheck")
          coreStore.dispatch({
            type: SET_USER_TOKEN,
            userToken: event.data.decodedUserToken
          })
          coreStore.dispatch({
            type: SET_ID_TOKEN,
            idToken: event.data.decodedIdToken,
            idTokenEncoded: event.data.idTokenEncoded
          })
          runTimeoutForRefreshToken(event.data.dateInterval)
        }
      },
      false
    )
  }, [])

  /**
   * On Destory hook
   */
  useEffect(
    () => () => {
      if (navigationSubscription) {
        navigationSubscription()
        navigationSubscription = null
      }
    },
    []
  )

  // -----------------------------------------------------------------------------------------
  // -------------------------------- Component Template -------------------------------------
  // -----------------------------------------------------------------------------------------

  return (
    <Provider store={store}>
      <NeoErrorBoundary>
        <NeoThemeProvider>
          <Intl.IntlProvider locale={"en"} messages={messages["en"]}>
            <NeoComponents.NeoTooltips />
            <Toastr />
            <Header
              name={appTitle}
              applications={applications}
              activeApp={activeApp}
              pageTitle={pageTitle}
              settingsSidebar={settingsSidebar}
            />
            {showSidebar && (
              <SideMenu
                isExpandedSideBar={isExpandedSideBar}
                setIsExpandedSideBar={setIsExpandedSideBar}
              />
            )}
            {!path && globalLoader && <GlobalLoader loaderBackground="" />}
            {showWhiteLoader && <GlobalLoader loaderBackground="#fff" />}
            {showEulaAcceptance && eulaObj && (
              <EulaAcceptanceModal
                setEulaObj={setEulaObj}
                setGlobalLoader={setGlobalLoader}
                eulaObj={eulaObj}
                popupOpenStatus={showEulaAcceptance}
                setPopupOpenStatus={setShowEulaAcceptance}
                hideHeader={false}
              />
            )}
            {foneAlertConfig.status && (
              <FoneAlert alertData={foneAlertConfig.alertProps} />
            )}
            <BrowserRouter basename="/">
              <Routes>
                <Route path={`/editprofile`} element={<UserProfileContainer />}>
                  <Route
                    path="*"
                    element={<Navigate to={`/editprofile`} replace />}
                  />
                </Route>
              </Routes>
            </BrowserRouter>
          </Intl.IntlProvider>
        </NeoThemeProvider>
      </NeoErrorBoundary>
    </Provider>
  )
}
